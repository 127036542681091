export default {
  methods: {
    generateComprehensiveAssessmentText(finalNoteText = '') {
      if (!(this.$custom.isObjectEmpty(this.encounter.encounter_covid_assessment))
        && (this.encounter.encounter_covid_assessment?.have_cough !== null
      || this.encounter.encounter_covid_assessment?.have_fever !== null
      || this.encounter.encounter_covid_assessment?.have_weakness !== null)) {

        const covidYesItems = []
        const covidNoItems = []
        if (this.encounter.encounter_covid_assessment?.have_cough === true) {
          covidYesItems.push('a cough')
        }
        if (this.encounter.encounter_covid_assessment?.have_cough === false) {
          covidNoItems.push('cough')
        }
        if (this.encounter.encounter_covid_assessment?.have_fever === true) {
          covidYesItems.push('a fever')
        }
        if (this.encounter.encounter_covid_assessment?.have_fever === false) {
          covidNoItems.push('fever')
        }
        if (this.encounter.encounter_covid_assessment?.have_weakness === true) {
          covidYesItems.push('weakness/lethargy')
        }
        if (this.encounter.encounter_covid_assessment?.have_weakness === false) {
          covidNoItems.push('weakness/lethargy')
        }

        finalNoteText += '<br><br>'
        finalNoteText += 'A comprehensive assessment of the '
        finalNoteText += this.$custom.randomString(["patient's health", 'health of the patient', 'health of this patient'])
        finalNoteText += 'was '
        finalNoteText += this.$custom.randomString(['performed', 'conducted', 'carried out'])
        finalNoteText += this.$custom.randomString(['', 'today'])
        finalNoteText += 'to rule out any '
        finalNoteText += this.$custom.randomString(['symptoms of COVID-19', 'COVID-19 symptoms', 'symptoms congruent with those of COVID-19'])
        finalNoteText += ', prior to consultation.  Patient was '
        finalNoteText += this.$custom.randomString(['found to have', 'determined to have', 'noted as having'])
        finalNoteText += this.$custom.toListCommaAnd(covidYesItems, null, 'lc')

        if (covidNoItems.length > 0) {
          if (covidYesItems.length > 0) {
            finalNoteText += ', but '
          }
          finalNoteText += 'no '
          finalNoteText += this.$custom.toListCommaOr(covidNoItems, null, 'lc')
        }
        finalNoteText += '. <br>'
      }

      return finalNoteText
    },
    generateFinalNoteText() {
      const medicalHistoryTitles = []
      if (this.encounter.medical_histories) {
        this.encounter.medical_histories.forEach(medicalHistory => {
          if (medicalHistory.title !== undefined) {
            medicalHistoryTitles.push(medicalHistory.title)
          }
        })
      }
      const riskFactorTitles = []
      if (this.encounter.risk_factors) {
        this.encounter.risk_factors.forEach(riskFactor => {
          if (riskFactor.title !== undefined) {
            riskFactorTitles.push(riskFactor.title)
          }
        })
      }
      const medicationTitles = []
      if (this.encounter.medications) {
        this.encounter.medications.forEach(medication => {
          if (medication.title === 'Other' && this.encounter.medications_other !== null) {
            medicationTitles.push(this.encounter.medications_other)
          } else if (medication.title !== undefined) {
            medicationTitles.push(medication.title)
          }
        })
      }
      const socialHistoryTitles = []
      if (this.encounter.social_histories) {
        this.encounter.social_histories.forEach(socialHistory => {
          if (socialHistory.title !== undefined) {
            socialHistoryTitles.push(socialHistory.title)
          }
        })
      }
      const generalAppearanceTitles = []
      if (this.encounter.general_appearances) {
        this.encounter.general_appearances.forEach(generalAppearance => {
          if (generalAppearance.title === 'Other' && this.encounter.general_appearances_other !== null) {
            generalAppearanceTitles.push(this.encounter.general_appearances_other)
          } else if (generalAppearance.title !== undefined) {
            generalAppearanceTitles.push(generalAppearance.title)
          }
        })
      }
      const bodyAreaTitles = []
      if (this.encounter.body_areas) {
        this.encounter.body_areas.forEach(bodyArea => {
          if (bodyArea.title !== undefined) {
            bodyAreaTitles.push(bodyArea.title)
          }
        })
      }
      let finalNoteText = ''
      if (this.encounter.visit_reason) {
        finalNoteText += 'REASON FOR VISIT:<br>'
        finalNoteText += this.$custom.textAreaHtml(this.encounter.visit_reason)
      }
      if (this.isFollowup) {
        finalNoteText += '<br><br>CHANGE IN PATIENT HEALTH:<br>'
        if (this.encounter.health_change) {
          if (this.encounter.health_change === 'Other' && this.encounter.health_change_other !== null) {
            finalNoteText += this.encounter.health_change_other
          } else {
            finalNoteText += this.encounter.health_change
          }
        }
      }
      if (!this.isFollowup) {
        finalNoteText += '<br><br>CHIEF COMPLAINT:<br>'
        if (this.encounter.chief_complaint) {
          finalNoteText += this.$custom.textAreaHtml(this.encounter.chief_complaint)
        }
        finalNoteText += '<br><br>HISTORY OF PRESENT ILLNESS:<br>'
        if (this.encounter.present_illness_history) {
          finalNoteText += this.$custom.textAreaHtml(this.encounter.present_illness_history)
        }
      }

      finalNoteText = this.generateComprehensiveAssessmentText(finalNoteText)

      if (this.encounter.encounter_covid_assessment?.covid_test_date) {
        finalNoteText += '<br><br>COVID-19 TEST:<br>'
        finalNoteText += 'A'
        let covidTestType = this.encounter.encounter_covid_assessment?.test_type
        if (covidTestType === 'Other') {
          covidTestType = this.encounter.encounter_covid_assessment?.other_type
        }
        if (['a', 'e', 'i', 'o', 'u'].includes(covidTestType.charAt(0).toLowerCase())) {
          finalNoteText += 'n'
        }
        finalNoteText += ' '
        finalNoteText += covidTestType
        finalNoteText += ' test was '
        finalNoteText += this.$custom.randomString(['performed on', 'conducted on', 'administered to'])
        finalNoteText += this.$custom.randomString(['the', 'this'])
        finalNoteText += 'patient on '
        finalNoteText += this.$custom.randomString(['date', 'the date of', 'the date'])
        finalNoteText += this.formatDate(this.encounter.encounter_covid_assessment?.covid_test_date)
        finalNoteText += ' to '
        finalNoteText += this.$custom.randomString(['check', 'review', 'audit', 'analyze'])
        finalNoteText += ' for '
        finalNoteText += this.$custom.randomString(['the possibility of a', 'a'])
        finalNoteText += ' COVID-19 infection.  '
        if (this.encounter.encounter_covid_assessment?.test_result === 'Pending') {
          finalNoteText += 'The results of the test are currently pending.'
        } else {
          finalNoteText += 'The results of '
          finalNoteText += this.$custom.randomString(['the', 'this'])
          finalNoteText += ' test showed '
          finalNoteText += this.$custom.randomString(['', 'that'])
          finalNoteText += 'the patient was '
          finalNoteText += this.$custom.toUpperCase(this.encounter.encounter_covid_assessment?.test_result)
          finalNoteText += ' for COVID-19.'
        }
      }
      if (medicalHistoryTitles.length > 0 || this.encounter.medical_histories_other) {
        finalNoteText += '<br><br>PAST MEDICAL HISTORY:<br>'
        if (medicalHistoryTitles.length > 0) {
          finalNoteText += this.$custom.toListCommaAnd(medicalHistoryTitles)
        }
        if (medicalHistoryTitles.length > 0 && this.encounter.medical_histories_other) {
          finalNoteText += ' as well as '
        }
        if (this.encounter.medical_histories_other) {
          finalNoteText += this.$custom.textAreaFlat(this.encounter.medical_histories_other)
        }
      }
      if (riskFactorTitles.length > 0 || this.encounter.risk_factors_other) {
        finalNoteText += '<br><br>RISK FACTORS:<br>'
        if (riskFactorTitles.length > 0) {
          finalNoteText += this.$custom.toListCommaAnd(riskFactorTitles)
        }
        if (riskFactorTitles.length > 0 && this.encounter.risk_factors_other) {
          finalNoteText += this.$custom.randomString(['in addition to ', 'as well as '])
        }
        if (this.encounter.risk_factors_other) {
          finalNoteText += this.$custom.textAreaFlat(this.encounter.risk_factors_other)
        }
      }
      finalNoteText += '<br><br>REVIEW OF SYSTEMS:<br>'
      if (this.encounter.systems_review) {
        finalNoteText += this.$custom.textAreaHtml(this.encounter.systems_review)
      }
      finalNoteText += '<br><br>MEDICATIONS:<br>'
      finalNoteText += medicationTitles.length > 0
        ? this.$custom.toListCommaAnd(medicationTitles, null, 'none')
        : ' None'
      finalNoteText += '<br><br>IS PATIENT ON ACTIVE THERAPEUTIC ANTICOAGULATION? '
      finalNoteText += this.encounter.therapeutic_anticoagulant ? 'Yes' : 'No'
      if (!this.isFollowup) {
        finalNoteText += '<br><br>FAMILY HISTORY:<br>'
        if (this.encounter.family_history_contributory !== null) {
          finalNoteText += this.encounter.family_history_contributory ? 'Contributory' : 'Non-contributory'
        }
        if (this.encounter.family_history) {
          finalNoteText += ' - '
          finalNoteText += this.$custom.textAreaFlat(this.encounter.family_history)
        }
        finalNoteText += '<br><br>SOCIAL HISTORY:<br>'
        finalNoteText += this.encounter.social_history_contributory ? 'Contributory' : 'Non-contributory'
        if (socialHistoryTitles.length > 0) {
          finalNoteText += ': '
          finalNoteText += this.$custom.toListComma(socialHistoryTitles)
        }
        if (this.encounter.social_history_other) {
          finalNoteText += ' - '
          finalNoteText += this.$custom.textAreaFlat(this.encounter.social_history_other)
        }
        finalNoteText += '<br><br>MOBILITY:<br>'
        if (this.encounter.mobility === 'Other' && this.encounter.mobility_other) {
          finalNoteText += this.$custom.textAreaHtml(this.encounter.mobility_other)
        } else if (this.encounter.mobility) {
          finalNoteText += this.encounter.mobility
        }
        finalNoteText += '<br><br>INCONTINENCE:<br>'
        if (this.encounter.continence === 'Other' && this.encounter.continence_other) {
          finalNoteText += this.$custom.textAreaHtml(this.encounter.continence_other)
        } else if (this.encounter.continence) {
          finalNoteText += this.encounter.continence
        }
      }
      finalNoteText += '<br><br>GENERAL APPEARANCE:<br>'
      if (generalAppearanceTitles.length > 0) {
        finalNoteText += this.$custom.toListCommaAnd(generalAppearanceTitles, null, 'none')
      }
      if (this.encounter.temperature || this.encounter.heart_rate || this.encounter.bp_systolic || this.encounter.bp_diastolic || this.encounter.respiratory_rate) {
        finalNoteText += '<br><br>VITAL SIGNS:<br>'
        if (this.encounter.temperature) {
          finalNoteText += `Temperature: ${this.encounter.temperature} °${this.encounter.temperature_scale}<br>`
        }
        if (this.encounter.heart_rate) {
          finalNoteText += `Heart Rate: ${this.encounter.heart_rate} bpm<br>`
        }
        if (this.encounter.bp_diastolic && this.encounter.bp_systolic) {
          finalNoteText += `Blood Pressure: ${this.encounter.bp_systolic}/${this.encounter.bp_diastolic} mmHg<br>`
        }
        if (this.encounter.respiratory_rate) {
          finalNoteText += `Respiratory Rate: ${this.encounter.respiratory_rate} breaths per minute`
        }
      }
      finalNoteText += '<br><br>MENTAL STATUS:<br>'
      if (this.encounter.mental_status) {
        finalNoteText += this.encounter.mental_status.replaceAll('A&O', 'Alert and oriented')
      }
      finalNoteText += '<br><br>EXAMINATION OF BODY AREAS:<br>'
      if (bodyAreaTitles.length > 0) {
        if (bodyAreaTitles.length > 7) {
          finalNoteText += 'A'
          if ([8, 11, 18].includes(bodyAreaTitles.length)) {
            finalNoteText += 'n'
          }
          finalNoteText += ' '
          finalNoteText += bodyAreaTitles.length
          finalNoteText += ' point comprehensive skin examination was performed of the following sites: '
          finalNoteText += this.$custom.toListCommaAnd(bodyAreaTitles, null, 'lc')
          finalNoteText += "to examine for any new or current lesions on the patient's body."
        } else if (bodyAreaTitles.length > 2) {
          finalNoteText += 'A detailed skin examination of the following areas was performed: '
          finalNoteText += this.$custom.toListCommaAnd(bodyAreaTitles, null, 'lc')
        } else {
          finalNoteText += 'A focused skin examination of the following areas was performed: '
          finalNoteText += this.$custom.toListCommaAnd(bodyAreaTitles, null, 'lc')
        }
      }
      if (this.encounter.body_areas_notes) {
        if (bodyAreaTitles.length > 0) {
          finalNoteText += `  Additionally: ${this.encounter.body_areas_notes}`
        }
      }
      if (this.encounter.encounter_pulse_assessment?.femoral_left || this.encounter.encounter_pulse_assessment?.femoral_right || this.encounter.encounter_pulse_assessment?.popliteal_left || this.encounter.encounter_pulse_assessment?.popliteal_right || this.encounter.encounter_pulse_assessment?.posterior_tibial_left || this.encounter.encounter_pulse_assessment?.posterior_tibial_right || this.encounter.encounter_pulse_assessment?.dorsalis_pedis_left || this.encounter.encounter_pulse_assessment?.dorsalis_pedis_right) {
        finalNoteText += '<br><br>'
        finalNoteText += this.$custom.randomString(['The following pulses were examined on the patient', 'A vascular exam was performed', 'Certain pulses were examined on this patient', "An examination of some of the patient's pulses was performed"])
        finalNoteText += 'and the results are as follows:'
        if (this.encounter.encounter_pulse_assessment?.femoral_left) {
          finalNoteText += '<br>Left femoral pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.femoral_left)
        }
        if (this.encounter.encounter_pulse_assessment?.femoral_right) {
          finalNoteText += '<br>Right femoral pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.femoral_right)
        }
        if (this.encounter.encounter_pulse_assessment?.popliteal_left) {
          finalNoteText += '<br>Left popliteal pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.popliteal_left)
        }
        if (this.encounter.encounter_pulse_assessment?.popliteal_right) {
          finalNoteText += '<br>Right popliteal pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.popliteal_right)
        }
        if (this.encounter.encounter_pulse_assessment?.posterior_tibial_left) {
          finalNoteText += '<br>Left posterior tibial pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.posterior_tibial_left)
        }
        if (this.encounter.encounter_pulse_assessment?.posterior_tibial_right) {
          finalNoteText += '<br>Right posterior tibial pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.posterior_tibial_right)
        }
        if (this.encounter.encounter_pulse_assessment?.dorsalis_pedis_left) {
          finalNoteText += '<br>Left dorsalis pedis pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.dorsalis_pedis_left)
        }
        if (this.encounter.encounter_pulse_assessment?.dorsalis_pedis_right) {
          finalNoteText += '<br>Right dorsalis pedis pulse: '
          finalNoteText += this.$custom.toLowerCase(this.encounter.encounter_pulse_assessment?.dorsalis_pedis_right)
        }
      }
      this.final_note.text = this.$custom.clean(finalNoteText, 'Wound Text', false)
    },
  },
}
